import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Experience {
  company: string;
  role: string;
  date: string;
  tasks: string[];
}

interface ExperienceListProps {
  experiences: Experience[];
}

const ExperienceList: React.FC<ExperienceListProps> = ({ experiences }) => {
  const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(0);
  const selectedExperience = experiences[selectedExperienceIndex];

  const lineHeight = 100 / experiences.length;
  const lineTop = lineHeight * selectedExperienceIndex;
  const { t } = useTranslation();
  const getLineBorderRadius = () => {
    if (selectedExperienceIndex === 0) {
      return "rounded-t-lg";
    } else if (selectedExperienceIndex === experiences.length - 1) {
      return "rounded-b-lg";
    } else {
      return "";
    }
  };

  return (
    <div className="flex-col">
      <h2 className="subheader-text place mb-4 font-semibold text-[#7282FF]">
        {t("WhereIveWorked")}
      </h2>
      <div className="mx-auto flex flex-col border-gray-200 sm:flex-row 2xl:flex-row">
        <div className="w-full pb-5 sm:w-1/4 2xl:w-1/3">
          <div className="relative" style={{ zIndex: 5 }}>
            <div
              className={`absolute top-0 w-1 bg-[#7274FF] transition-all duration-300 ease-in-out ${getLineBorderRadius()}`}
              style={{
                width: `6px`,
                height: `${lineHeight}%`,
                top: `${lineTop}%`,
              }}
            ></div>
            <ul className="list-none">
              {experiences.map((exp, index) => (
                <li
                  key={exp.company}
                  className={`body-text cursor-pointer py-2 pl-4 ${
                    index === selectedExperienceIndex
                      ? "text-[#7282FF]"
                      : "text-gray-600"
                  } hover:frosted-glass hover:text-[#4f51c7]`}
                  onClick={() => setSelectedExperienceIndex(index)}
                >
                  {exp.company}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full pl-2 md:w-3/4 2xl:w-2/3">
          <h3 className="body-text font-semibold">
            {selectedExperience.role} @ {selectedExperience.company}
          </h3>
          <p className="body-text mb-2">{selectedExperience.date}</p>
          <div className="h-72 space-y-2 overflow-auto">
            {selectedExperience.tasks.map((task, index) => (
              <p key={index} className="body-text">
                {task}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceList;
