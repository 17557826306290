import React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center">
        <h1 className="header-text mb-4 font-bold text-gray-800">404</h1>
        <p className="body-text mb-8 text-gray-600">
          Oops! The page you're looking for does not exist.
        </p>
        <a
          href="/"
          className="body-text text-blue-600 underline hover:text-blue-700"
        >
          Go back to the homepage
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
