import React from "react";
import { useTranslation } from "react-i18next";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto px-4">
      <section className="py-8 md:py-12">
        <h2 className="subheader-text mb-6 text-center text-[#7282FF] md:text-5xl">
          {t("ContactTitle")}
        </h2>
        <p className="body-text mb-6 text-center text-gray-600">
          {t("ContactTagline")}
        </p>
        <div className="body-text text-center">
          <a
            href="mailto:anthonybellon1000@gmail.com"
            className="rounded-full bg-[#7282FF] px-6 py-3 font-semibold text-white shadow-lg transition-colors duration-300 hover:bg-[#4f51c7]"
          >
            {t("ContactEmailMe")}
          </a>
        </div>
      </section>
      {/* Footer with subtle styling */}
      <footer className="py-6 text-center md:py-8">
        <p className="text-gray-500">{t("AllRightsReserved")}</p>
      </footer>
    </div>
  );
};

export default HomePage;
