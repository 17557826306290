import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getProjectsData from "../data/projectsData"; // Function to get localized data
import { useTranslation } from "react-i18next"; // Import useTranslation
import LinkIcon from "../components/Icons/LinkIcon";

const ProjectDetailPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const projectsData = getProjectsData(t); // Dynamically localized project data
  const project = projectsData.find((p) => p.id === projectId);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isAutoScroll, setIsAutoScroll] = useState(
    (project?.screenshots?.length ?? 0) > 1,
  );
  const [opacity, setOpacity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const changeImage = () => {
      setOpacity(0);
      setTimeout(() => {
        setSelectedImageIndex((prevIndex) =>
          prevIndex === (project?.screenshots?.length ?? 0) - 1
            ? 0
            : prevIndex + 1,
        );
        setOpacity(1);
      }, 300);
    };

    let intervalId: NodeJS.Timeout;
    if (isAutoScroll && (project?.screenshots?.length ?? 0) > 1) {
      intervalId = setInterval(changeImage, 3000);
    }

    return () => clearInterval(intervalId);
  }, [isAutoScroll, project?.screenshots?.length]);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setIsAutoScroll(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsAutoScroll(false);
  };

  if (!project) {
    return <div className="section-content">Project not found</div>;
  }

  return (
    <div className="project-section-content mx-auto content-center rounded-lg p-4">
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="frosted-glass p-4 md:w-2/5">
          <div className="mb-4 flex items-center gap-2">
            <img
              src={project.iconUrl}
              alt={`${project.name} icon`}
              className="h-12 w-12 rounded-lg"
            />
            <h1 className="subheader-text font-bold">{project.name}</h1>
            {project.projectUrl && (
              <div>
                <a
                  href={project.projectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`inline-block rounded-full ${project.urlButtonColor} ${project.urlLightButtonColor} px-1 py-1 text-white`}
                >
                  <LinkIcon />
                </a>
              </div>
            )}
          </div>
          <p className="body-text mb-4">{project.description}</p>
          {project.description2 && (
            <h2 className="body-text mb-4">{project.description2}</h2>
          )}

          <div className="flex flex-wrap gap-2">
            {project.techStack.map((tech, index) => (
              <span
                key={index}
                className={`body-text rounded-full px-4 py-1 text-white ${project.buttonColor}`}
              >
                {tech}
              </span>
            ))}
          </div>
        </div>
        {project.screenshots.length > 0 && (
          <div className="md:w-3/5">
            <div className="mx-auto mb-8 max-w-2xl 2xl:max-w-4xl">
              <img
                className="frosted-glass w-full duration-300"
                style={{ opacity: opacity }}
                src={project.screenshots[selectedImageIndex]}
                alt={`Screenshot ${selectedImageIndex + 1}`}
                onClick={handleOpenModal}
              />
            </div>
            {project.screenshots.length > 1 && (
              <div className="flex justify-center">
                <div className="mx-auto grid max-w-2xl grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                  {project.screenshots.map((screenshot, index) => (
                    <img
                      className={`cursor-pointer rounded-lg shadow-md ${
                        index === selectedImageIndex ? "opacity-50" : ""
                      }`}
                      key={index}
                      src={screenshot}
                      alt={`Screenshot ${index + 1}`}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 flex w-full items-center justify-center bg-black bg-opacity-75"
          style={{ zIndex: 8 }}
          onClick={handleCloseModal} // Close modal on background click
        >
          <img
            src={project.screenshots[selectedImageIndex]}
            className="lg:max-h-screen lg:max-w-screen-lg" // Limiting the size of the image
            alt={`Screenshot ${selectedImageIndex + 1}`}
          />
          <button
            className="absolute right-4 top-4 text-3xl text-white"
            onClick={handleCloseModal}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailPage;
