import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const navLinks = [
    { key: "about", name: t("About"), path: "/#about" },
    {
      key: "work-experience",
      name: t("WorkExperience"),
      path: "/#work-experience",
    },
    { key: "portfolio", name: t("Portfolio"), path: "/#portfolio" },
    { key: "contact", name: t("Contact"), path: "/#contact" },
  ];

  return (
    <div className="navigation-bar sticky top-0" style={{ zIndex: 6 }}>
      <div className="nav-text topnav flex space-x-4 p-3">
        {navLinks.map((link) => (
          <Link
            key={link.key}
            to={link.path}
            smooth
            className="nav-text text-[#1c1c1c] transition-colors duration-300 hover:text-[#4f51c7]"
          >
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navigation;
