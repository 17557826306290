import React from "react";
import AboutPage from "../pages/AboutPage";
import HomePage from "../pages/HomePage";
import PortfolioPage from "../pages/PortfolioPage";
import WorkExperiencePage from "../pages/WorkExperiencePage";

const MainPage: React.FC = () => {
  return (
    <div>
      <div id="about" className="section-content about-specific">
        <AboutPage />
      </div>
      <div id="work-experience" className="section-content experience-specific">
        <WorkExperiencePage />
      </div>
      <div id="portfolio" className="section-content">
        <PortfolioPage />
      </div>
      <div id="contact" className="section-content">
        <HomePage />
      </div>
    </div>
  );
};

export default MainPage;
