import { TFunction } from "i18next";
import zentimeIcon from "../images/Zentime/zentimeIcon.jpeg";
import zentimeNew1 from "../images/Zentime/zentimeNew1.png";
import zentimeNew2 from "../images/Zentime/zentimeNew2.png";
import certinity1 from "../images/certinity/certinity1.png";
import certinity2 from "../images/certinity/certinity2.png";
import certinity3 from "../images/certinity/certinity3.png";
import certinity4 from "../images/certinity/certinity4.png";
import certinity5 from "../images/certinity/certinity5.png";
import certinity6 from "../images/certinity/certinity6.png";
import certinity7 from "../images/certinity/certinity7.png";
import certinityIcon from "../images/certinity/certinityIcon.png";
import velibIcon from "../images/velib/velibIcon.png";
import velib1 from "../images/velib/velib1.png";
import velib2 from "../images/velib/velib2.png";
import dateGenius1 from "../images/dateGenius/dateGenius1.png";
import dateGenius2 from "../images/dateGenius/dateGenius2.png";
import dateGenius3 from "../images/dateGenius/dateGenius3.png";
import dateGeniusIcon from "../images/dateGenius/dateGeniusIcon.png";

const projectsData = (t: TFunction) => [
  {
    id: "1",
    name: t("detailedProjectTitle4"),
    iconUrl: dateGeniusIcon,
    screenshots: [dateGenius1, dateGenius2, dateGenius3],
    description: t("detailedProjectDescription4"),

    techStack: ["Typescript", "NextJS", "Pinecone", "Firebase", "Vercel"],
    buttonColor: "bg-[#337357]",
    urlLightButtonColor: "bg-[#337357]",
    urlButtonColor: "hover:bg-[#275944]",
    projectUrl: "https://dategenius.vercel.app",
  },
  {
    id: "2",
    name: t("detailedProjectTitle1"),
    iconUrl: velibIcon,
    screenshots: [velib1, velib2],
    description: t("detailedProjectDescription1"),
    techStack: ["Python", "NextJS", "TailwindCSS", "Vercel"],
    buttonColor: "bg-[#8a3284]",
    urlLightButtonColor: "bg-[#8a3284]",
    urlButtonColor: "hover:bg-[#601A51]",
    projectUrl: "https://velib-visualisation.vercel.app",
  },
  {
    id: "3",
    name: t("detailedProjectTitle2"),
    iconUrl: zentimeIcon,
    screenshots: [zentimeNew1, zentimeNew2],
    description: t("detailedProjectDescription2"),
    description2: t("detailedProjectDescription2_1"),
    techStack: ["Flutter", "AdobeXD", "Trello"],
    buttonColor: "bg-[#6DC7AD]",
  },
  {
    id: "4",
    name: t("detailedProjectTitle3"),
    iconUrl: certinityIcon,
    screenshots: [
      certinity1,
      certinity2,
      certinity3,
      certinity4,
      certinity5,
      certinity6,
      certinity7,
    ],
    description: t("detailedProjectDescription3"),
    techStack: ["Typescript", "ReactJS", "Material Ui", "Solidity"],
    buttonColor: "bg-[#2D6266]",
  },
];

export default projectsData;
