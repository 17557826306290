import React from "react";
import ExperienceList from "../components/ExperienceList";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const WorkExperiencePage: React.FC = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  interface Experience {
    company: string;
    role: string;
    date: string;
    tasks: string[];
  }

  const getExperiences = (): Experience[] => [
    {
      company: t("company1"),
      role: t("role1"),
      date: t("date1"),
      tasks: [t("tasksMG1of2"), t("tasksMG2of2")],
    },
    {
      company: t("company2"),
      role: t("role2"),
      date: t("date2"),
      tasks: [t("tasksGW1of2"), t("tasksGW2of2")],
    },
    {
      company: t("company3"),
      role: t("role3"),
      date: t("date3"),
      tasks: [t("tasksQ1of2"), t("tasksQ2of2")],
    },
  ];

  return (
    <div>
      <section className="pt-8 md:mx-0 md:px-10 md:pt-20">
        <ExperienceList experiences={getExperiences()} />
      </section>
    </div>
  );
};

export default WorkExperiencePage;
