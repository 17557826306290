import React from "react";
import anthonyPortrait from "../images/anthony-portrait.jpg"; // Ensure the path is correct
import { useTranslation } from "react-i18next";

const AboutPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Function to change language
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="flex flex-col sm:flex-row lg:flex-row">
      <div className="md:order-1 md:flex-1">
        <header className="pb-1 pl-4">
          <h1 className="header-text mb-3 font-bold text-[#7274FF]">
            {t("Title")}
          </h1>
        </header>
        <section className="px-5">
          <p className="body-text mx-0 text-left md:max-w-lg lg:max-w-2xl">
            {t("TitleTagline")}
          </p>
        </section>
        <section className="pl-4 pt-5">
          <div className="subheader-text mb-5 font-bold text-[#7274FF]">
            {t("Skills")}
          </div>
          <div className="pb-5 pr-5">
            <div className="flex flex-col justify-start md:flex-row">
              <div className="body-text">{t("FrontEnd")}</div>
            </div>
            <div>
              <div className="body-text text-left">{t("TechnicalSkills")}</div>
            </div>
          </div>
          <div>
            <div className="2xl:w-1/ pb-10 pr-5 md:w-2/4">
              <div className="body-text mb-2 font-semibold">
                {t("Languages")}
              </div>
              <div className="body-text text-left">
                <button
                  onClick={() => changeLanguage("en")}
                  className="mr-2 inline-block rounded-full bg-[#7274FF] px-3 py-1.5 font-bold text-white transition-colors duration-300 ease-in-out hover:bg-[#4f51c7]"
                >
                  {t("English")}
                </button>
                <button
                  onClick={() => changeLanguage("fr")}
                  className="mr-2 inline-block rounded-full bg-[#7274FF] px-3 py-1.5 font-bold text-white transition-colors duration-300 ease-in-out hover:bg-[#4f51c7]"
                >
                  {t("French")}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="flex items-center justify-center md:order-2">
        <img
          src={anthonyPortrait}
          alt="Anthony Bellon"
          className="emerge-animation h-auto w-full max-w-40 rounded-lg sm:max-w-96 md:max-w-72 lg:max-w-80"
        />
      </div>
    </div>
  );
};

export default AboutPage;
