// src/i18n.ts

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

interface Resources {
  [key: string]: {
    translation: {
      [key: string]: string;
    };
  };
}

const resources: Resources = {
  en: {
    translation: {
      // Navigation
      About: "About",
      WorkExperience: "Work Experience",
      Portfolio: "Projects",
      Contact: "Contact",
      // About Page
      Title: "Full Stack Developer",
      TitleTagline: "Embracing the Latest Tech with a Global Perspective.",
      Skills: "Skills",
      FrontEnd: "Technical:",
      TechnicalSkills:
        "React, JavaScript, TypeScript, Next.js, Flutter, HTML5, CSS, Vue.js, Python",
      Languages: "Languages:",
      English: "English",
      MotherTongue: "Mother Tongue",
      French: "French",
      Fluent: "Fluent",

      // Work Experience
      WhereIveWorked: "Where I’ve Worked",
      company1: "Medic Global",
      role1: "Front-end Developer",
      date1: "Dec. 2022 - Aug. 2023",
      tasksMG1of2:
        "Contributed to the development of innovative mobile applications and web platforms using TypeScript, ReactJS, and React Native, focusing on patient care management.",
      tasksMG2of2:
        "Implemented Agile development methodologies, participated in iterative design and development meetings, and provided critical feedback to guide the refinement and optimization of each version of the application.",
      company2: "Goodwave",
      role2: "Front-end Developer",
      date2: "Oct. 2021 - Feb. 2022",
      tasksGW1of2:
        "Designed, developed, and successfully launched a communication feature within a cross-platform mobile and web application called Zentime using Adobe XD and Flutter. The feature was utilized internally, aiding iterative refinement.",
      tasksGW2of2:
        "Organized tasks and guided junior team members, ensuring efficient collaboration.",
      company3: "Qiimcy",
      role3: "Front-end Developer",
      date3: "Apr. 2021 - Aug. 2021",
      tasksQ1of2:
        "Gained expertise in JavaScript and Next.js, prioritizing clean code.",
      tasksQ2of2:
        "Communicated effectively with team leads and management. Enhanced user experience by translating designs into responsive web interfaces, modernizing the company's online presence.",
      // Portfolio
      PortfolioTitle: "Projects",
      projectTitle1: "Velib Visualisation",
      projectDescription1:
        "Website predicting the number of available Vélib bikes at each station in Paris. Coded in Python for the algorithm, with an interface in NextJS and hosted on Vercel.",
      projectTitle2: "Zentime",
      projectDescription2:
        "Developed ZenTime, an employee coordination platform with Flutter, enhancing organizational efficiency through presence monitoring and direct communication.",

      projectTitle3: "Certinity",
      projectDescription3:
        "Developed the front-end for Certinity, a WEB3 solution utilizing blockchain for secure and immutable diploma certification, with a focus on intuitive UI/UX using TypeScript and Material UI.",
      projectTitle4: "Date Genius",
      projectDescription4:
        "An AI-powered chatbot platform that helps users discover the best events in Paris by leveraging open-source data to provide personalized event recommendations.",
      // Card
      WorkInProgress: "Work in Progress",
      LearnMore: "Learn More",
      // Contact
      ContactTitle: "Contact",
      ContactTagline:
        "Code your vision to life – get in touch with the front-end developer 👋",
      ContactEmailMe: "Email Me",
      AllRightsReserved: "© 2024 Anthony Bellon. All rights reserved.",
      // Detailed Project Page
      detailedProjectTitle1: "Velib Visualisation",
      detailedProjectDescription1:
        "I have developed a website that predicts the number of Vélib bikes available at each station in Paris. The algorithm is coded in Python, with a NextJS interface and hosted on Vercel. The website is designed to help users plan their trips based on bike availability. You can search for bike availability by day of the week and hour of the day.",
      detailedProjectTitle2: "Zentime",
      detailedProjectDescription2:
        "ZenTime was an employee coordination solution developed using Flutter. This platform was designed to optimize organizational efficiency by monitoring employee presence and tracking activities. ZenTime also facilitated direct and immediate communication between employers and their teams, enabling smoother management and better responsiveness. ",
      detailedProjectDescription2_1:
        "The project included creating interactive mockups with AdobeXD to visualize and refine application features, and developing these mockups into a cross-platform communication tool for web and mobile. Tasks were organized and collaboration was streamlined using Trello.",
      detailedProjectTitle3: "Certinity",
      detailedProjectDescription3:
        "Collaborated on Certinity, a WEB3 application enabling certifying authorities to securely generate and certify diplomas using blockchain technology. Led front-end development and UI/UX design, utilizing TypeScript and Material UI. Contributed to overall project design and implementation, ensuring seamless integration of decentralized systems for certification security.",
      detailedProjectTitle4: "Date Genius",
      detailedProjectDescription4:
        "Created the DateGenius platform, which helps users discover the best events in Paris using open-source event data. It combines traditional filters like keywords, cost, and date range with AI-powered conversations for personalized event recommendations. The system filters out irrelevant intents and, if the user’s intent is “find an event,” embeddings and Retrieval-Augmented Generation (RAG) are applied to generate tailored suggestions.",
    },
  },
  fr: {
    translation: {
      // Navigation
      About: "À propos",
      WorkExperience: "Expérience Pro.",
      Portfolio: "Projets",
      Contact: "Contact",

      // About Page
      Title: "Développeur Full Stack",
      TitleTagline:
        "Adopter les dernières technologies avec une perspective mondiale.",
      Skills: "Compétences",
      FrontEnd: "Compétences:",
      TechnicalSkills:
        "React, JavaScript, TypeScript, Next.js, Flutter, HTML5, CSS, Vue.js, Python",
      Languages: "Langues:",
      English: "Anglais",
      French: "Français",

      // Work Experience
      WhereIveWorked: "Expériences professionnelles",
      company1: "Medic Global",
      role1: "Développeur Front-end",
      date1: "Déc. 2022 - Août 2023",
      tasksMG1of2:
        "Contribué au développement d'applications mobiles et de plateformes web en utilisant TypeScript, ReactJS et React Native, en se concentrant sur la gestion des soins aux patients.",
      tasksMG2of2:
        "Appliqué des méthodologies Agile, participé à des réunions de développement itératives et fourni des retours essentiels pour guider le raffinement et l'optimisation de chaque version de l'application.",
      company2: "Goodwave",
      role2: "Développeur Front-end",
      date2: "Oct. 2021 - Fév. 2022",
      tasksGW1of2:
        "Conçu, développé et lancé une fonctionnalité de communication dans une appli multiplateforme avec Adobe XD et Flutter. Utilisée en interne, elle a facilité le raffinement itératif.",
      tasksGW2of2:
        "Organisé les tâches et encadré les juniors pour une collaboration efficace.",
      company3: "Qiimcy",
      role3: "Développeur Front-end",
      date3: "Avr. 2021 - Août 2021",
      tasksQ1of2:
        "Acquis une expertise en JavaScript et Next.js, en mettant l'accent sur un code propre.",
      tasksQ2of2:
        "Communiqué efficacement avec les responsables et la direction. Amélioré l'expérience utilisateur entransformant les designs en interfaces web réactives, modernisant la présence en ligne de l'entreprise.",

      // Portfolio
      PortfolioTitle: "Projets",
      projectTitle1: "Velib Visualisation",
      projectDescription1:
        "Site web prédisant le nombre de vélos Vélib disponibles à chaque station à Paris. Codé en Python pour l’algorithme, avec une interface en NextJS et hébergé sur Vercel.",
      projectTitle2: "Zentime",
      projectDescription2:
        "Développé ZenTime, une plateforme de coordination des employés avec Flutter, améliorant l'efficacité organisationnelle grâce à la surveillance de la présence et à la communication directe.",
      projectTitle3: "Certinity",
      projectDescription3:
        "Développé l'interface front-end pour Certinity, une solution WEB3 qui exploite la blockchain pour la certification de diplômes sécurisée et inaltérable, avec un accent sur une interface intuitive utilisant TypeScript et Material UI.",
      projectTitle4: "Date Genius",
      projectDescription4:
        "Une plateforme de chatbot alimentée par l’IA qui aide les utilisateurs à découvrir les meilleurs événements à Paris en utilisant des données open-source pour offrir des recommandations personnalisées.",

      // Card
      WorkInProgress: "Travail en cours",
      LearnMore: "En savoir plus",

      // Contact
      ContactTitle: "Contact",
      ContactTagline:
        "Codez votre vision pour donner vie à vos projets – contactez le développeur front-end 👋",
      ContactEmailMe: "Envoyez-moi un e-mail",
      AllRightsReserved: "© 2024 Anthony Bellon. Tous droits réservés.",

      // Detailed Project Page
      detailedProjectTitle1: "Velib Visualisation",
      detailedProjectDescription1:
        "J'ai développé un site web qui prédit le nombre de vélos Vélib disponibles à chaque station à Paris. L'algorithme est codé en Python, avec une interface en NextJS et hébergé sur Vercel. Le site a été conçu pour aider les utilisateurs à planifier leurs déplacements en fonction de la disponibilité des vélos. Vous pouvez rechercher la disponibilité des vélos par jour de la semaine et par heure de la journée.",
      detailedProjectTitle2: "Zentime",
      detailedProjectDescription2:
        "ZenTime était une solution de coordination des employés développée avec Flutter. Cette plateforme était conçue pour optimiser l'efficacité organisationnelle en surveillant la présence des employés et en suivant leurs activités. ZenTime facilitait également la communication directe et immédiate entre les employeurs et leurs équipes, permettant une gestion plus fluide et une meilleure réactivité.",
      detailedProjectDescription2_1:
        "Le projet comprenait la création de maquettes interactives avec AdobeXD pour visualiser et affiner les fonctionnalités de l'application, et le développement de ces maquettes en un outil de communication multiplateforme pour le web et le mobile. Les tâches étaient organisées et la collaboration était rationalisée grâce à Trello.",
      detailedProjectTitle3: "Certinity",
      detailedProjectDescription3:
        "J'ai collaboré sur Certinity, une application WEB3 permettant aux autorités certifiantes de générer et de certifier de manière sécurisée les diplômes grâce à la technologie blockchain. J'ai dirigé le développement front-end et la conception UI/UX, en utilisant TypeScript et Material UI. J'ai aussi contribué à la conception et à la mise en œuvre globale du projet, assurant une intégration transparente des systèmes décentralisés pour la sécurité de la certification.",
      detailedProjectTitle4: "Date Genius",
      detailedProjectDescription4:
        "Création de la plateforme DateGenius, qui aide les utilisateurs à découvrir les meilleurs événements à Paris en utilisant des données open-source. La plateforme combine des filtres traditionnels (mots-clés, coût, plage de dates) avec des conversations alimentées par l’IA pour des recommandations personnalisées. Le système filtre les intentions non pertinentes et, si l’intention de l’utilisateur est de “trouver un événement”, des embeddings et la génération augmentée par récupération (RAG) sont utilisés pour proposer des suggestions sur mesure.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
