import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LinkIcon from "./Icons/LinkIcon";

interface CardProps {
  title: string;
  description: string;
  linkUrl: string;
  inProgress?: boolean;
  projectUrl?: string; // New prop for the project URL
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  linkUrl,
  inProgress = false,
  projectUrl, // New prop for the project URL
}) => {
  const { t } = useTranslation();
  return (
    <div className="max-h-full py-2 transition duration-300 ease-in-out">
      <div className="flex h-full flex-col overflow-hidden rounded-3xl bg-white shadow-lg transition-shadow duration-300 ease-in-out hover:shadow-2xl">
        <div className="flex-grow p-6">
          <h3 className="subheader-text mb-2 text-xl font-semibold">{title}</h3>
          <p className="body-text mb-4 text-gray-600">{description}</p>
        </div>
        <div className="flex items-center justify-between px-6 pb-6">
          {projectUrl && (
            <a
              href={projectUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full bg-blue-100 p-2 text-[#7282FF] transition-colors duration-300 ease-in-out hover:bg-[#4f51c7] hover:text-white"
            >
              <LinkIcon />
            </a>
          )}
          {inProgress ? (
            <button
              className="ml-auto inline-block rounded-full bg-gray-500 px-4 py-2 font-bold text-white"
              disabled
            >
              {t("WorkInProgress")}
            </button>
          ) : (
            <Link
              to={linkUrl}
              className="ml-auto inline-block rounded-full bg-[#7282FF] px-4 py-2 font-bold text-white transition-colors duration-300 ease-in-out hover:bg-[#4f51c7]"
            >
              {t("LearnMore")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
