import React, { useState, useEffect } from "react";
import "./App.css";
import Navigation from "./navigation/Navigation";
import AppRoutes from "./navigation/AppRoutes";
import { useLocation } from "react-router-dom";
import Spline from "@splinetool/react-spline";
import { tailChase } from "ldrs";
import anthonyPortrait from "./images/anthony-portrait.jpg";
import WebFont from "webfontloader";

const App: React.FC = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [splineLoaded, setSplineLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    tailChase.register();
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = anthonyPortrait;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Bebas Neue",
          "Poppins:100,200,300,400,500,600,700,800,900&display=swap",
        ],
      },
      active: () => {
        setFontsLoaded(true);
      },
      inactive: () => {
        console.error("Fonts could not be loaded");
        setFontsLoaded(true);
      },
    });
  }, []);

  const handleSplineLoad = () => {
    setSplineLoaded(true);
  };

  useEffect(() => {
    if (splineLoaded && imageLoaded && fontsLoaded) {
      const delay = setTimeout(() => {
        setLoading(false);
        setTimeout(() => setShowLoadingScreen(false), 2000); // After 10 seconds, hide the loading screen
      }, 1000);
      return () => clearTimeout(delay);
    }
  }, [splineLoaded, imageLoaded, fontsLoaded]);

  const definedPaths = ["/", "/projects/:projectId"];
  const isDefinedPath = definedPaths.some(
    (path) =>
      path === location.pathname ||
      (path.includes(":") && location.pathname.startsWith(path.split(":")[0])),
  );

  return (
    <div className="app-container">
      {showLoadingScreen && (
        <div className={`loading-screen ${loading ? "" : "hidden"}`}>
          <l-tail-chase size="50" speed="1.75" color="#7274FF"></l-tail-chase>
        </div>
      )}

      <div className={`spline-container ${loading ? "hidden" : ""}`}>
        <Spline
          scene="/assets/scene.splinecode"
          onLoad={handleSplineLoad}
          renderOnDemand={true}
        />
      </div>

      <div
        className={`content-container ${
          loading ? "hidden" : ""
        } mx-auto px-4 sm:px-6 md:max-w-screen-lg lg:px-8 2xl:max-w-screen-xl`}
      >
        {isDefinedPath && <Navigation />}
        <AppRoutes />
      </div>
    </div>
  );
};

export default App;
