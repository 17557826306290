import React from "react";
import Card from "../components/Card";
import { useTranslation } from "react-i18next";

interface Project {
  title: string;
  description: string;
  linkUrl: string;
  inProgress?: boolean;
  projectUrl?: string;
}

const PortfolioPage: React.FC = () => {
  const { t } = useTranslation();
  const projects: Project[] = [
    {
      title: t("projectTitle4"),
      description: t("projectDescription4"),
      linkUrl: "/projects/1",
      projectUrl: "https://dategenius.vercel.app",
    },
    {
      title: t("projectTitle1"),
      description: t("projectDescription1"),
      linkUrl: "/projects/2",
      projectUrl: "https://velib-visualisation.vercel.app",
    },
    {
      title: t("projectTitle2"),
      description: t("projectDescription2"),
      linkUrl: "/projects/3",
    },
    {
      title: t("projectTitle3"),
      description: t("projectDescription3"),
      linkUrl: "/projects/4",
    },
  ];

  return (
    <div>
      <section className="frosted-glass mx-2 rounded-lg px-4 py-8 md:mx-0 md:px-5 md:py-12">
        <h2 className="subheader-text mb-6 text-center font-semibold text-[#7282FF]">
          {t("Portfolio")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 2xl:gap-6">
          {projects.map((project, index) => (
            <Card
              key={index}
              title={project.title}
              description={project.description}
              linkUrl={project.linkUrl}
              inProgress={project.inProgress}
              projectUrl={project.projectUrl}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default PortfolioPage;
